<template>
    <svg class="logo" width="255" height="70" viewBox="0 0 255 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.1208 36.1268C24.1208 29.3127 21.5906 25.9062 16.5338 25.9062C15.4359 25.9062 14.3509 26.0488 13.2775 26.3317C12.2042 26.6158 11.3331 26.9706 10.6643 27.3961V46.6315C11.1893 46.7266 11.8569 46.8089 12.6684 46.8797C13.4798 46.9504 14.3625 46.9863 15.3166 46.9863C18.2268 46.9863 20.4226 45.9927 21.9016 44.0054C23.3806 42.018 24.1208 39.3919 24.1208 36.1268ZM35.0002 36.4109C35.0002 39.3919 34.5583 42.0888 33.6755 44.5016C32.7928 46.9156 31.516 48.9737 29.8464 50.6769C28.1756 52.3801 26.1248 53.6938 23.6917 54.6167C21.2574 55.5385 18.4899 56 15.3891 56C14.1006 56 12.7525 55.9409 11.3448 55.8226C9.9371 55.7043 8.55392 55.5501 7.19297 55.3611C5.83435 55.1721 4.53302 54.9484 3.29249 54.6875C2.05079 54.4278 0.954074 54.1553 0 53.8712V1.70358L10.6643 -0.000823975V18.5958C11.8569 18.0752 13.0986 17.6728 14.3871 17.3888C15.6756 17.1047 17.0587 16.9633 18.5378 16.9633C21.2094 16.9633 23.5712 17.4247 25.6232 18.3476C27.6751 19.2694 29.3927 20.5831 30.7771 22.2863C32.1602 23.9896 33.2113 26.0372 33.9269 28.4257C34.6425 30.8153 35.0002 33.4775 35.0002 36.4109Z" fill="black"/>
        <path d="M64.7975 33.1731C64.75 32.259 64.5961 31.3685 64.3368 30.5016C64.0775 29.6359 63.6748 28.8657 63.1319 28.191C62.5891 27.5175 61.9039 26.9631 61.0775 26.5303C60.2512 26.0974 59.2234 25.8804 57.9954 25.8804C56.8148 25.8804 55.7998 26.0856 54.9491 26.4949C54.0995 26.9042 53.39 27.4456 52.8241 28.119C52.2569 28.7937 51.8194 29.5745 51.5127 30.4662C51.2049 31.3567 50.9815 32.259 50.8391 33.1731H64.7975ZM40 37.2884C40 33.9198 40.5069 30.971 41.5231 28.4446C42.5382 25.9169 43.8727 23.8115 45.5266 22.1261C47.1782 20.4417 49.0799 19.1655 51.2292 18.2998C53.3785 17.4329 55.5868 17 57.8542 17C63.1435 17 67.3241 18.6489 70.3947 21.9456C73.4641 25.2434 75 30.0935 75 36.4946C75 37.1197 74.9757 37.8062 74.9294 38.5516C74.8819 39.2994 74.8333 39.9599 74.787 40.5379H50.8391C51.0752 42.753 52.0914 44.5092 53.8866 45.809C55.6806 47.1088 58.0903 47.7575 61.1134 47.7575C63.0498 47.7575 64.9502 47.5771 66.8171 47.2161C68.6829 46.8552 70.2049 46.4106 71.3866 45.881L72.8032 54.6174C72.2373 54.9064 71.4803 55.1954 70.5359 55.4844C69.5914 55.7733 68.5405 56.0246 67.3831 56.2416C66.2257 56.4586 64.9861 56.6391 63.6644 56.783C62.3403 56.9281 61.0185 57 59.6956 57C56.3414 57 53.4259 56.4952 50.9468 55.4844C48.4664 54.4724 46.412 53.09 44.7824 51.3314C43.1528 49.5751 41.9479 47.4945 41.169 45.0872C40.3889 42.6798 40 40.0814 40 37.2884Z" fill="black"/>
        <path class="color" d="M104.797 33.1731C104.75 32.259 104.596 31.3685 104.337 30.5016C104.078 29.6359 103.675 28.8657 103.132 28.191C102.589 27.5175 101.904 26.9631 101.078 26.5303C100.251 26.0974 99.2234 25.8804 97.9954 25.8804C96.8148 25.8804 95.7998 26.0856 94.9491 26.4949C94.0995 26.9042 93.39 27.4456 92.8241 28.119C92.2569 28.7937 91.8194 29.5745 91.5127 30.4662C91.2049 31.3567 90.9815 32.259 90.8403 33.1731H104.797ZM80 37.2884C80 33.9198 80.5069 30.971 81.5231 28.4446C82.5382 25.9169 83.8727 23.8115 85.5266 22.1261C87.1782 20.4417 89.0799 19.1655 91.2292 18.2998C93.3785 17.4329 95.5868 17 97.8542 17C103.144 17 107.324 18.6489 110.395 21.9456C113.464 25.2434 115 30.0935 115 36.4946C115 37.1197 114.976 37.8062 114.929 38.5516C114.882 39.2994 114.833 39.9599 114.787 40.5379H90.8403C91.0752 42.753 92.0914 44.5092 93.8866 45.809C95.6806 47.1088 98.0903 47.7575 101.113 47.7575C103.05 47.7575 104.95 47.5771 106.817 47.2161C108.683 46.8552 110.206 46.4106 111.387 45.881L112.803 54.6174C112.237 54.9064 111.48 55.1954 110.536 55.4844C109.591 55.7733 108.541 56.0246 107.383 56.2416C106.226 56.4586 104.986 56.6391 103.664 56.783C102.34 56.9281 101.019 57 99.6956 57C96.3414 57 93.4259 56.4952 90.9468 55.4844C88.4664 54.4724 86.412 53.09 84.7824 51.3314C83.1528 49.5751 81.9479 47.4945 81.169 45.0872C80.39 42.6798 80 40.0814 80 37.2884Z" fill="#FF7200"/>
        <path class="color" d="M173.671 47.8601C174.706 47.8601 175.695 47.8369 176.638 47.7895C177.579 47.7433 178.334 47.6728 178.898 47.5768V39.5791C178.475 39.4854 177.839 39.3918 176.991 39.2958C176.144 39.2021 175.366 39.1547 174.66 39.1547C173.671 39.1547 172.741 39.2137 171.87 39.3316C170.998 39.4496 170.233 39.6739 169.575 40.0034C168.914 40.3341 168.396 40.7827 168.02 41.3493C167.643 41.9147 167.455 42.6223 167.455 43.4721C167.455 45.1244 168.007 46.2679 169.115 46.905C170.221 47.5421 171.739 47.8601 173.671 47.8601ZM172.823 17C175.932 17 178.521 17.3538 180.594 18.0626C182.666 18.769 184.325 19.7854 185.574 21.1058C186.821 22.4274 187.704 24.0323 188.222 25.9181C188.74 27.8062 189 29.906 189 32.2184V54.1604C187.492 54.4911 185.397 54.8796 182.713 55.3282C180.029 55.7757 176.78 56 172.965 56C170.562 56 168.385 55.7873 166.431 55.3629C164.476 54.9386 162.793 54.2425 161.38 53.2747C159.967 52.3081 158.883 51.0455 158.131 49.4881C157.376 47.9318 157 46.0205 157 43.7554C157 41.5851 157.435 39.7444 158.307 38.2344C159.177 36.7243 160.343 35.5218 161.804 34.6246C163.264 33.7285 164.936 33.0798 166.819 32.6775C168.702 32.2774 170.657 32.0762 172.682 32.0762C174.047 32.0762 175.261 32.1363 176.32 32.2531C177.38 32.3711 178.238 32.526 178.898 32.7133V31.7224C178.898 29.9302 178.357 28.4907 177.274 27.405C176.19 26.3205 174.307 25.777 171.623 25.777C169.833 25.777 168.066 25.9077 166.324 26.1655C164.581 26.4257 163.076 26.7922 161.804 27.2628L160.462 18.769C161.073 18.5817 161.84 18.3806 162.757 18.1678C163.676 17.9562 164.676 17.7677 165.76 17.6012C166.842 17.4371 167.984 17.296 169.186 17.1769C170.387 17.0601 171.599 17 172.823 17Z" fill="#FF7200"/>
        <path class="color" d="M195 37C195 34.2566 195.435 31.67 196.306 29.238C197.176 26.8083 198.434 24.69 200.083 22.8843C201.729 21.0797 203.729 19.6479 206.082 18.5887C208.435 17.5308 211.117 17 214.129 17C216.105 17 217.917 17.1805 219.564 17.5414C221.211 17.9023 222.811 18.4201 224.365 19.0935L222.176 27.6859C221.188 27.3013 220.105 26.964 218.929 26.6751C217.752 26.3861 216.435 26.2422 214.976 26.2422C211.87 26.2422 209.552 27.2294 208.023 29.2026C206.494 31.177 205.73 33.7754 205.73 37C205.73 40.4181 206.446 43.0648 207.882 44.9424C209.318 46.819 211.822 47.7578 215.399 47.7578C216.67 47.7578 218.034 47.6387 219.494 47.3969C220.951 47.1563 222.293 46.7718 223.517 46.2422L225 55.0504C223.775 55.5799 222.246 56.0364 220.412 56.4221C218.576 56.8066 216.552 57 214.341 57C210.953 57 208.035 56.4822 205.588 55.4467C203.141 54.4135 201.129 53.0052 199.552 51.223C197.976 49.4432 196.823 47.3379 196.094 44.9059C195.364 42.4762 195 39.8413 195 37Z" fill="#FF7200"/>
        <path class="color" d="M230 7.73141L240.673 6V17.1829H253.496V26.1299H240.673V39.4779C240.673 41.7397 241.068 43.543 241.855 44.889C242.644 46.2362 244.231 46.9092 246.619 46.9092C247.766 46.9092 248.948 46.8008 250.165 46.5851C251.383 46.3682 252.493 46.0688 253.496 45.6834L255 54.0529C253.71 54.5821 252.278 55.0382 250.702 55.4225C249.126 55.8079 247.192 56 244.9 56C241.986 56 239.575 55.604 237.665 54.8096C235.754 54.0164 234.227 52.9108 233.08 51.4905C231.934 50.0727 231.134 48.3518 230.68 46.3317C230.226 44.3115 230 42.0756 230 39.6217V7.73141Z" fill="#FF7200"/>
        <path class="color" d="M157 9H144.662L135.5 24.6719L131.29 17.4684H118.935L129.322 35.2374L109 70H121.337L135.492 45.7901L141.243 55.6268H153.598L141.669 35.2234L157 9Z" fill="#FF7200"/>
    </svg>
</template>

<script>
export default {
}
</script>