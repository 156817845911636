<template>
    <div class="before-container-orange">
    </div>
    <footer class="container-orange">
        <div class="container">
            <BeexactLogo />

            <div class="contact">
                <h2>Contact</h2>

                <div class="adress">
                    beeXact Europe Headquarters<br />
                    Bergweidedijk 38<br />
                    7418 AA Deventer <br />
                    Netherlands<br />
                    <a class="phone" href="tel:+31570745622">+31 570 – 745 622</a><br />
                    <br />
                    beeXact United States Headquarters<br />
                    8061 Bellflower Court<br />
                    Niwot, CO 80503<br />
                    United States<br />

                </div>
                
                <div class="links">
                    <a class="mail" href="mailto:info@beeXact.com">info@beeXact.com</a><br />
                    <router-link to="/" class="web">www.beeXact.com</router-link>
                </div>
            </div>

            <div class="social">
                <h2>Follow us</h2>

                <a target="_blank" class="linkedin" href="https://www.linkedin.com/company/beexact">LinkedIn</a>
                <a target="_blank" class="instagram" href="https://instagram.com/beexact_/">Instagram</a>
            </div>

            <div class="menu">
                <h2>Menu</h2>
            
                <router-link to="/">Home</router-link>
                <!-- <router-link to="/expertises">Expertises</router-link> -->
                <router-link to="/about">About beeXact</router-link>
                <!-- <router-link to="/services">Services</router-link> -->
                <router-link to="/news">News</router-link>
                <router-link to="/jobs">Jobs</router-link>
                <!--<a target="blank" href="https://werkenbij.beexact.com/">Jobs</a>!-->
                <router-link to="/contact">Contact Us</router-link>
                <router-link to="/solutions">Solutions</router-link>
                <router-link to="/infrastructure-owners">Infrastructure Owners</router-link>
                <router-link to="/infrastructure-operators">Infrastructure Operators</router-link>
                <router-link to="/contractors">Contractors</router-link>
                <router-link to="/government">Government</router-link>
            </div>

            <div class="rights">
                &copy; beeXact - {{new Date().getFullYear()}}<br />
                All Rights Reserved<br />
                <!-- <router-link to="/privacy-statement">Privacy statement</router-link> -->
            </div>
        </div>
    </footer>
</template>

<script>
import BeexactLogo from '@/components/BeexactLogo.vue'
export default {
  components: {
    BeexactLogo,
  },
}
</script>

<style>
footer {
    position: relative;
    padding-bottom: 150px;
    overflow: hidden;
}
footer .logo path.color {
    fill:#fff;
}
footer h2 {
    color: #fff;
}
footer a {
    color: #000;
    text-decoration: none;
}

footer .container {
    z-index: 1;
    position: relative;
}
footer .social a {
    display: inline-block;
    position: relative;
    width: 46px;
    height: 46px;
    border-radius: 100%;
    background: #000;
    color: #fff;

    line-height: 46px;
    text-align: center;

    margin-right: 15px;
    text-indent: -99999px;
}
footer .social a:before {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 24px;
    line-height: 24px;

    width: 46px;
    height: 24px;
    text-align: center;
    text-indent: 0;
    position: absolute;
    left: 0;
    top: 11px;
}
footer .social a.instagram:before {
    content: "\e905";
}
footer .social a.linkedin:before {
    content: "\e901";
}
footer .social a:hover {
    background: #fff;
    color: #ff7200;
}

footer .contact a {
    padding: 0 0 0 20px;
    display: inline-block;
    position: relative;
}
footer .contact a:before {
    display: block;
    position: absolute;
    left: 0;
    color: #fff;
}
footer .contact .links .mail,
footer .contact .links .web,
footer .contact .phone {
    padding-left:70px;
}
footer .contact .phone:before {
    content: "Phone";
}
footer .contact .links .mail:before {
    content: "Email";
}
footer .contact .links .web:before {
    content: "Website";
}

footer .contact a:hover {
    color: #fff;
    font-weight: bold;
}
footer .contact a:hover:before {
    left: 5px;
    font-weight: normal;
}

footer .menu a {
    display:block;
    line-height: 25px;

    padding: 0 0 0 30px;
    position: relative;
}
footer .menu a:before {
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
    color: #fff;
    content: "\e900";

    
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 14px;
    line-height: 14px;

    height: 14px;
    width: 10px;
}
footer a:hover {
    color: #fff;
    font-weight: bold;
}
footer .menu a:hover {
    padding: 0 0 0 35px;
}
footer .menu a:hover:before {
    left: 5px;
}
@media (min-width: 992px) {
    footer h2 {
        margin-bottom: 25px;
    }
    footer .logo {
        position: absolute;
        top: 76px;
        left: 20px;
    }
    footer .rights {
        position: absolute;
        bottom: 0;
        left: 20px;
    }

    footer .contact h2 {
        position: absolute;
        top: 44px;
        left: 40%;
    }
    footer .contact .adress {
        position: absolute;
        top: 107px;
        left: 40%;
    }
    footer .contact .links {
        position: absolute;
        bottom:0px;
        left: 40%;
    }


    footer .menu {
        position: relative;
        padding: 44px 0 0 0;
        padding-bottom:80px;
        left: 67%;
        width: 210px;
    }

    footer .social {
        position: absolute;
        top: 44px;
        right: 20px;
    }
}

@media (max-width: 991px) {
    footer .logo {
        margin-top: 76px;
    }
    footer .container > div {
        margin: 50px 0;
    }
    footer .container > div h2 {
        margin-bottom: 40px;
    }
    footer .contact .adress {
        margin-bottom: 40px;
    }
}

@media (max-width: 991px) and (min-width: 550px) {
    footer .social {
        position: absolute;
        width: 200px;
        right: 20px;
        top: 146px;

        text-align: right;
    }
    footer .social a:last-child {
        margin: 0;
    }
    footer .container > div.rights {
        position: absolute;
        width: 200px;
        right: 20px;
        bottom: 0;
        margin: 0;
        text-align: right;
    }
}

</style>