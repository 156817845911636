<template>
    <div class="backgound-element">
        <div class="top-triangle"></div>
    </div>
    <HeaderComponent />
    
    <div class="router-placeholder">
        <router-view></router-view>
    </div>

    <FooterComponent />
</template>

<script>

import { ref, onBeforeMount, onMounted, onUnmounted } from 'vue'
// import { loadLocaleMessages } from '@/i18n'

import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'


var fadeInElements = ref()
const handleScroll = () => {
    for (var i = 0; i < fadeInElements.value.length; i++) {
        var el = fadeInElements.value[i]
        var rect = el.getBoundingClientRect()

        if(rect.bottom < 0) {
            // boven het scherm
            el.classList.add('is-above-screen')
            el.classList.remove('is-in-screen')
            if(el.firstChild) {
                if(el.firstChild.classList.contains('parallax-vertical')) {
                    el.firstChild.style.transform = "translateY(" + window.innerHeight/20 + "px)";
                }
                if(el.firstChild.classList.contains('parallax-horizontal-left')) {
                    el.firstChild.dataset.translateX = 0;
                    el.firstChild.style.transform = "translateX(-0px)";
                }
            }
        } else if(rect.top < window.innerHeight) {
            // op het scherm
            el.classList.add('is-in-screen')
            el.classList.remove('is-above-screen')
            if(el.firstChild) {
                if(el.firstChild.classList.contains('parallax-vertical')) {
                    el.firstChild.style.transform = "translateY(" + window.innerHeight * ((0.4*(rect.bottom/(rect.height+window.innerHeight)))-0.2) + "px)";
                }
                if(el.firstChild.classList.contains('parallax-horizontal-left')) {
                    if(rect.bottom/(rect.height+window.innerHeight) < 0.5) {
                        el.firstChild.style.transform = "translateX(-0px)";
                    } else {
                        // hier niet terug zetten
                        var translateX = (Math.pow(-2*((rect.bottom/(rect.height+window.innerHeight)) - 0.5), 2)) * -30;
                        if(el.firstChild.dataset.translateX == undefined || translateX > el.firstChild.dataset.translateX) {
                            el.firstChild.dataset.translateX = translateX
                            el.firstChild.style.transform = "translateX(" + translateX + "px)";
                        }
                    }
                }
            }
        } else {
            // onder het scherm
            el.classList.remove('is-in-screen')
            el.classList.remove('is-above-screen')
            if(el.firstChild) {
                if(el.firstChild.classList.contains('parallax-vertical')) {
                    el.firstChild.style.transform = "translateY(-" + window.innerHeight/20 + "px)";
                }
                if(el.firstChild.classList.contains('parallax-horizontal-left')) {
                    el.firstChild.dataset.translateX = -30;
                    el.firstChild.style.transform = "translateX(-30px)";
                }
            }
        }
    }
}

export default {
    components: {
        HeaderComponent,
        FooterComponent,
    },
    setup() {
        onBeforeMount(() => {
            // loadLocaleMessages(i18n, localStorage.getItem('i18n/lang') || 'en')
            // console.log(this.$i18n)
        })
        onMounted(() => {
            fadeInElements.value = document.getElementsByClassName('check-in-screen')
            document.addEventListener('scroll', handleScroll)
            window.addEventListener('resize', handleScroll)
            handleScroll()
        })
        onUnmounted(() => {
            document.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleScroll)
        })
    },
    data() {
        return {
            showNavbar: false,
        }
    },
    methods: {
        toggleNavbar() {
            this.showNavbar = !this.showNavbar
        }
    }
}
</script>

<style>
* {
    font-family: 'Cairo', sans-serif;
    --bs-gutter-x: 40px !important;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
::marker {
  content: "»";
  font-size: 1.5em;
  color:#FF7200;
  line-height:2rem;
}
li {
    padding-inline-start: 1ch;
    line-height:2rem;
    padding-left:10px;
}
.brand-active ::marker {
    color:#fff;
}
.btn {
    --bs-btn-border-radius: 2px !important;
}
.btn-primary {
    --bs-btn-bg: #0092F2 !important;
    --bs-btn-border-color: #0092F2 !important;
    --bs-btn-padding-x: 1.75rem !important;
    font-size:18px !important;
}
.text-orange {
    color: #FF7200;
}
.text-white {
    color: #fff;
}
.img-stretch {
    width: 100%;
    height: auto;
}
body {
    position: relative;
}
.router-placeholder {
    min-height: 1000px;
}

div.biggest,
h1.biggest,
h2.biggest,
h3.biggest {
    font-size: 70px;
}

div.big,
h1.big,
h2.big,
h3.big {
    font-size: 52px;
}
div.smaller,
h1.smaller,
h2.smaller,
h3.smaller {
    font-size: 32px;
}
div.smallest,
h1.smallest,
h2.smallest,
h3.smallest {
    font-size: 24px;
}

@media (max-width: 767px) {
    div.biggest,
    h1.biggest,
    h2.biggest,
    h3.biggest {
        font-size: 52px;
    }
}

blockquote {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 59px;
    position: relative;
    padding-bottom: 60px;
}

blockquote:after {
    content: '';
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 78px;
    height: 0px;
    border-bottom: 7px solid #FF7200;
}
@media (max-width: 767px) {
    blockquote {
        font-size: 30px;
        line-height: 38px;
    }
}
.btn {
    text-transform: uppercase;
}
.my-50 {
    margin-left: 50px;
    margin-right: 50px;
}
.mx-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}
.mx-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}
.mt-100 {
    margin-top: 100px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-100 {
    margin-bottom: 100px;
}
.mb-130 {
    margin-bottom: 130px;
}
.mx-200 {
    margin-top: 200px;
    margin-bottom: 200px;
}
.mb-200 {
    margin-bottom: 200px;
}
.mt-200 {
    margin-top: 200px;
}
.top-triangle {
    position: absolute;

    pointer-events: none;

    top: 0;
    left: 0;
    width: 263px;
    transform: skewX(-30deg);
    transform-origin: right top;
    
    z-index: -1;
    overflow: hidden;
}
body.blank .top-triangle {
    background: linear-gradient(270deg, #EDEDED 5.9%, #FFFFFF 99.36%);
    height: 220px;
}

@media (max-width: 767px) {
    body.blank .top-triangle {
        height: 110px;
    }
}
@media (min-width: 768px) {
    body.blank .top-triangle {
        height: 220px;
    }
}
body.orange .top-triangle {
    background: linear-gradient(90deg, #fff 40%, #EDEDED 100%);
    height: max(100%, 100vh);
}

body.orange .top-triangle:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(66deg, #FF7200 0%, #FF9700 100%);

    height: 100%;
    width: 100%;
}
body.orange .top-triangle:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    
    height: 100%;
    width: 100%;
    transform: skewX(49deg);
    transform-origin: left top;
    background: #FF7200;
}

body.orange.orange-contact .top-triangle:before,
body.orange.orange-contact .top-triangle:after {
    height: 620px;
}
@media (max-width: 767px) {
    body.orange.orange-contact .top-triangle:before,
    body.orange.orange-contact .top-triangle:after {
        height: 510px;
    }
}

@media (max-width: 767px) {
    body.orange.orange-home .top-triangle:before,
    body.orange.orange-home .top-triangle:after {
        height: 510px;
    }
}

body.orange.orange-home .top-triangle:before,
body.orange.orange-home .top-triangle:after {
    height: 110px;
}
@media (min-width: 576px) {
    body.orange.orange-home .top-triangle:before,
    body.orange.orange-home .top-triangle:after {
        height: 391px;
    }
    .intro-orange .title-over-image img {
        min-height:242px;
    }
}
@media (min-width: 768px) {
    body.orange.orange-home .top-triangle:before,
    body.orange.orange-home .top-triangle:after {
        height: 603px;
    }
    .intro-orange .title-over-image img {
        min-height:330px;
    }
}
@media (min-width: 992px) {
    body.orange.orange-home .top-triangle:before,
    body.orange.orange-home .top-triangle:after {
        height: 738px;
    }
    .intro-orange .title-over-image img {
        min-height:446px;
    }
}
@media (min-width: 1200px) {
    body.orange.orange-home .top-triangle:before,
    body.orange.orange-home .top-triangle:after {
        height: 839px;
    }
    .intro-orange .title-over-image img {
        min-height:533px;
    }
}
@media (min-width: 1400px) {
    body.orange.orange-home .top-triangle:before,
    body.orange.orange-home .top-triangle:after {
        height: 940px;
    }
    .intro-orange .title-over-image img {
        min-height:620px;
    }
}


.intro-orange {
    background: #FF7200;
    position: relative;
}
.intro-orange:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    pointer-events: none;

    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    transform: skewX(-30deg);
    transform-origin: right top;
    
    z-index: 0;
    background: linear-gradient(66deg, #FF7200 0%, #FF9700 100%);
}
.intro-orange .container {
    position: relative;
    z-index: 1;
}

.container-right-overflow-indent,
.container-right-overflow,
.container-left-overflow {
    padding: 0 20px;
    overflow: hidden;
}
.container-right-overflow {
    padding: 0 20px 0 0;
}

.container-left-overflow {
    padding: 0 0 0 20px;
}
.container-right-overflow-indent {
    padding: 100px 20px;
}

.container-right-overflow-indent .fill-container,
.container-right-overflow .fill-container,
.container-left-overflow .fill-container {
    position: absolute;
    left: -10000%;
    right: -10000%;
    top: -10001%;
    bottom: -10000%;
    min-width: 1000%;
    min-height: 1010%;
    transform: scale(0.1);
    max-width: none;
    margin: auto;
}

@media (min-width: 576px) {
    .top-triangle {
        width: calc(50vw - 270px + 370px)
    }
    .intro-orange:before {
        width: calc(50vw - 270px + 307px)
    }

    .container-right-overflow {
        margin-left: calc(50vw - 260px);
    }
    .container-left-overflow {
        margin-right: calc(50vw - 260px);
    }
}
@media (min-width: 768px) {
    .top-triangle {
        width: calc(50vw - 360px + 498px)
    }
    .intro-orange:before {
        width: calc(50vw - 360px + 370px)
    }
    .container-right-overflow-indent {
        margin-left: calc(50vw - 350px + 100px);
        padding-right:  calc(50vw - 350px);
    }
    .container-right-overflow {
        margin-left: calc(50vw - 350px);
    }
    .container-left-overflow {
        margin-right: calc(50vw - 350px);
    }
}
@media (min-width: 992px) {
    .top-triangle {
        width: calc(50vw - 480px + 498px)
    }
    .intro-orange:before {
        width: calc(50vw - 480px + 370px)
    }
    

    .container-right-overflow-indent {
        margin-left: calc(50vw - 470px + 100px);
        padding-right:  calc(50vw - 470px);
    }
    .container-right-overflow {
        margin-left: calc(50vw - 470px);
    }
    .container-left-overflow {
        margin-right: calc(50vw - 470px);
    }
}
@media (min-width: 1200px) {
    .top-triangle {
        width: calc(50vw - 570px + 498px)
    }
    .intro-orange:before {
        width: calc(50vw - 570px + 370px)
    }

    .container-right-overflow-indent {
        margin-left: calc(50vw - 560px + 100px);
        padding-right:  calc(50vw - 560px);
    }
    .container-right-overflow {
        margin-left: calc(50vw - 560px);
    }
    .container-left-overflow {
        margin-right: calc(50vw - 560px);
    }
}
@media (min-width: 1400px) {
    .top-triangle {
        width: calc(50vw - 660px + 498px)
    }
    .intro-orange:before {
        width: calc(50vw - 660px + 370px)
    }

    .container-right-overflow-indent {
        margin-left: calc(50vw - 650px + 100px);
        padding-right: calc(50vw - 650px);
    }
    .container-right-overflow {
        margin-left: calc(50vw - 650px);
    }
    .container-left-overflow {
        margin-right: calc(50vw - 650px);
    }
}

.container-orange {
    background: #ff7200;
}

.container-orange:before {
    content: '';

    position: absolute;
    background: linear-gradient(120deg, #FF9700 0%, #FF7200 100%);
    pointer-events: none;

    top: 0;
    right: 0;
    height: 100%;
    width: 30vw;
    transform: skewX(30deg);
    transform-origin: right top;
    
    overflow: hidden;
}

.before-container-orange {
    position: relative;
}
.before-container-orange:before {
    content: '';

    position: absolute;
    background: linear-gradient(184deg, rgba(255,255,255,0) 52%, #EDEDED 100%);
    pointer-events: none;

    bottom: 0;
    right: 0;
    height: 50px;
    width: 30vw;
    transform: skewX(30deg);
    transform-origin: right bottom;
    
    overflow: hidden;
}

@media (max-width: 991px) {
    .container-orange:before,
    .before-container-orange:before {
        width: 60vw;
    }
}


@media (min-width: 768px) {
    .lined-element {
        background: #FFFFFF;
        box-shadow: 0px 0px 24px 9px rgba(0, 0, 0, 0.04);
        padding: 56px 100px;
        position: relative;
    }
    .lined-element:before {
        content: '';
        border-left: 4px solid #FF7200;
        left: 70px;
        top: 56px;
        bottom: 56px;
        position: absolute;
    }
}
@media (max-width: 767px) {
    .lined-element {
        border-bottom: 4px solid #FF7200;
        padding: 50px 0;
        margin-bottom: 50px;
    }
}

.parallax-container img {
    max-width: 100%;
}

.contact-us {
    
}
.contact-us .container {
    z-index: 2;
    position: relative;
}
.contact-us .contact-text .btn {
    margin-top:10px;
}
.our-expertises {
    padding-bottom:150px;
}
@media (min-width: 768px) {
    .contact-us .parallax-container img {
        margin-top:140px;
        margin-bottom:175px;
    }
    .contact-us .contact-text {
        border-top: 1px solid #D8D8D8;
        border-bottom: 1px solid #D8D8D8;

        margin: 150px 0;
        padding: 40px 0;
    }
}
@media (max-width: 767px) {
    .contact-us .parallax-container {
        width: 100%;
        padding-top: 40px;
        display: flex;
        justify-content: center;
    }
    .contact-us img {
        max-width: 75%;
        display: block;
    }
    .contact-us .contact-text {
        padding: 40px 0;
    }
}


.service-slider .splide__track,
.solution-slider .splide__track {
    z-index: 2;
}
.service-slider .splide__arrow,
.solution-slider .splide__arrow {
    z-index: 3;
    background: #fff;
}
.service-slider .splide__arrow svg,
.solution-slider .splide__arrow svg {
    fill: #ff7200;
}
.service-slider .splide__arrow:disabled svg,
.solution-slider .splide__arrow:disabled svg {
    fill: #D8D8D8;
}
.fact {
    background-color:#F3F3F3;
    text-align:center;
    padding:30px;
    padding-top:0px;
}
.fact .image {
    height:200px;
    line-height:200px;
}
.fact .number {
    font-weight:bold;
}
.fact .text {
    font-size:20px;
    color:#000;
    font-weight:600;
}
.laptop-container {
    /*background: linear-gradient(180deg, #FF7200 0%, #FF9700 100%);*/

    text-align:right;
    padding-top:100px;
    padding-bottom:100px;
}
.orangeGradient {
    background-image:url('/public/img/laptop_bg.png');
    background-size:33% 100%;
    background-repeat:no-repeat;
}
.laptop-container img {
    width:80%;

}
@media (max-width: 767px) {
    .orangeGradient {
        background-size:66% 40%;
    }   
    .img-half-container img {
        max-width: 100%;
        height: auto;
    }
}
@media (max-width: 460px) {
    .orangeGradient {
        background-size:66% 30%;
    }
}
@media (min-width: 768px) {
    .img-half-container {
        position: absolute;
        width: 46%;
        top: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }
    .img-half-container img {
        position: absolute;
        left:-10000%; right: -10000%; 
        top: -10000%; bottom: -10000%;
        margin: auto auto;
        min-width: 1000%;
        min-height: 1000%;
        -webkit-transform:scale(0.1);
        transform: scale(0.1);
    }
}



@media (max-width: 767px) {
    .intro-orange .title-over-image h1 {
        position: relative;
        padding: 30px 0;
        color: #fff;
    }
    .intro-orange .title-over-image img {
        position: relative;
        margin-bottom: -75px;
        z-index:-1;
    }
    .intro-orange .title-over-image {
        margin-bottom: 75px;
    }
}

@media (min-width: 768px) {
    .intro-orange .title-over-image {
        padding-top: 78px;
    }
    .intro-orange .video-container {
        padding-top: 78px;
    }
    
    .intro-orange .title-over-image h1,
    .intro-orange .title-over-image h2 {
        position: absolute;
        padding: 7vw;
        z-index: 3;
    }

    .over-image-above,
    .far-over-image-above {
        top: -50px;
        position: relative;
        z-index: 2;
        font-size: 24px;
    }
}
@media (min-width: 992px) {
    .far-over-image-above {
        top: -250px;
        margin-bottom: -200px;
    }
}
#introOrangeNews .title-over-image h1 {
    position:static;
    padding: 0px;
    color:#fff;
    padding-top:15px;
    padding-bottom:40px;
}
#introOrangeNews .title-over-image  h2 {
    color:#fff;
    position:absolute;
    bottom:0px;
}
@media (max-width: 767px) {
    #introOrangeNews .title-over-image  h2 {
        padding:30px;
        top:30px;
    }
}
#introOrangeNews .title-over-image  h2 sub {
    font-size:16px;
    line-height:0px;
    font-weight:normal;
}
.relevant-news {
    background: linear-gradient(0deg, #FFFFFF 0%, #F1F1F1 100%);
    padding-top:130px;
}
.relevant-news h2.relevant-news-header {
    font-weight: 400;
    font-size: 70px;
    line-height: 38px;
    margin-bottom:100px;
}
.quote {
    font-weight: 400;
    font-size: 50px;
    line-height: 57px;
    margin-top:80px;
    margin-bottom:80px;
}
@media (max-width: 767px) {
    .quote {
        font-size: 30px;
    }
}
.readmore {
    color:#3FA2F7;
    text-decoration:none;
}

.news-item-container {
    border-bottom: 1px solid #D8D8D8;
    padding-bottom:40px;
    margin-bottom:40px;
}
.news-item-container:last-child {
    border: 0;
}
@media (min-width: 767px) {
    div.vertical-align-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}
.news-item-container .date {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}
.news-item-container h3 {
    font-weight: 600;
    font-size: 35px;
    line-height: 38px;
    padding-bottom:30px;
    margin-bottom:30px;
    position: relative;
}
.news-item-container h3:after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 78px;
    height: 0px;
    border-bottom: 7px solid #FF7200;
}
@media (max-width: 767px) {
    .news-item-container .date {
        margin-top: 40px;
    }
}
.newArticle .intro {
    font-weight:bold;
    font-size:24px;
}
.intro-orange video {
    width: 100%;
    height: auto;
    display: block;
}
@media (max-width: 575px) {
    .intro-orange .video-container {
        width: 100%;
        padding: 0;
    }
}
.service-slider {
    position: relative;
    overflow: hidden;

    background: linear-gradient(0deg, #FFFFFF 19.34%, #F1F1F1 89.59%);
}
.service-slide {
    height: 780px;
    padding: 15px;
    text-align: center;
}
.service-slide img {
    width: 100%;
    height: auto;
    border:10px solid white;
}
.service-slide h3 {
    padding: 45px 0 15px 0;
}
@media (max-width: 575px) {
    .service-slide {
        max-width: 100vw;
        height: calc(400vw / 3.38 + 210px)
    }
}

@media (min-width: 576px) {
    .service-slide {
        width: 500px;
    }
}
@media (min-width: 768px) {
    .service-slide {
        width: calc(680px / 3);
        height: 530px;
    }
}
@media (min-width: 992px) {
    .service-slide {
        width: calc(920px / 3);
        height: 600px;
    }
}
@media (min-width: 1200px) {
    .service-slide {
        width: calc(1100px / 3);
        height: 680px;
    }
}
@media (min-width: 1400px) {
    .service-slide {
        width: calc(1280px / 3);
        height: 730px;
    }
}
.service-slider .check-in-screen .splide__slide .service-slide {
    transition-property: opacity;
    transition-duration: 0.3s;
    transition-delay: 0s;

    opacity: 0;
}
.check-in-screen .splide__slide:nth-child(2) .service-slide {
    transition-delay: 0.1s;
}
.service-slider .check-in-screen .splide__slide:nth-child(3) .service-slide {
    transition-delay: 0.2s;
}
.service-slider .check-in-screen .splide__slide:nth-child(4) .service-slide {
    transition-delay: 0.3s;
}
.service-slider .check-in-screen.is-in-screen .splide__slide .service-slide {
    opacity: 1;
}
.service-slider .check-in-screen.is-above-screen .splide__slide,
.service-slider .check-in-screen.is-in-screen .splide__slide {
    opacity: 0.5;
    transition-property: opacity;
    transition-duration: 0.5s;
}
.service-slider .check-in-screen.is-above-screen .splide__slide.is-visible,
.service-slider .check-in-screen.is-in-screen .splide__slide.is-visible {
    opacity: 1;
}
.service-slider .splide__pagination__page.is-active {
    background: #FF7200;
    opacity: 1;
}
.service-slider .splide__pagination {
    bottom: -30px;
    z-index:2;
}

.solutions-filters button,
.solutions-tags button {
    box-sizing: border-box;
    background: #FFFFFF;
    color:#BCBCBC;
    border: 1px solid #BCBCBC;
    border-radius: 80px;
    padding-left:50px;
    padding-right:50px;
    padding-top:10px;
    padding-bottom:10px;
    font-size:20px;
    line-height:25px;
    margin-left:10px;
    margin-right:10px;
    margin-top:20px;
}
.solutions-filters button.active,
.solutions-filters button:hover,
.solutions-tags button.active,
.solutions-tags button:hover {
    background-color:#FF7200;
    color:#fff;
}
</style>
